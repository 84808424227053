import { IconSvg } from "../../base/Icon/Icon.map";

interface IAlertPromptStyle {
  background: string;
  border: string;
  icon?: IconSvg;
}

export const alertPromptStyleMap: Record<string, IAlertPromptStyle> = {
  error: {
    background: "bg-white",
    border: "border-orange",
    icon: "crossHeavy",
  },
  warning: {
    background: "bg-white",
    border: "border-orange",
    icon: "warning",
  },
  success: {
    background: "bg-white",
    border: "border-green",
    icon: "tickHeavy",
  },
  idea: {
    background: "bg-white",
    border: "border-blue",
    icon: "lightBulb",
  },
  // idea: {
  //   background: "bg-white",
  //   border: "border-green",
  //   icon: "lightBulb",
  // },
  hidden: {
    background: "bg-inherit",
    border: "border-inherit",
  },
  discount: {
    background: "bg-white",
    border: "border-pink",
    icon: "tag",
  },

} as const;

export const alertPromptSizeMap: Record<string, string> = {
  default: "md:w-96",
  fill: "",
} as const;

export type TAlertStyle = keyof typeof alertPromptStyleMap;
export type TAlertSize = keyof typeof alertPromptSizeMap;
